<template>
  <section class="app-container" ref="pmRef">
      <div class="mb12">
        <FilterLayout @doReset="resetForm"
                      @doSearch="doSearch"
                      ref="filterLayoutRef">
          <template #customform>
            <a-form :model="formState" layout="vertical" ref="formRef" class="form rms-form">
              <a-form-item label="搜索" name="pmId" class="rms-form-item">
                <a-select
                    v-model:value="formState.pmId"
                    placeholder="选择PM"
                    show-search
                    allowClear
                    :filterOption="filterOption"
                >
                  <a-select-option
                      v-for="item in pmListForSearch"
                      :key="item.ggid"
                      :value="
                  item.fullName +
                  '/' +
                  item.email +
                  item.fullName.replace(', ', '') +
                  item.fullName.replace(', ', ',')
                "
                  >
                    {{ item.fullName + "/" + item.email }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                  label="Engagement Description"
                  name="engagementDescription"
                  class="rms-form-item"
              >
                <a-input
                    v-model:value="formState.engagementDescription"
                    placeholder="项目名称"
                    enter-button
                    allowClear
                />
              </a-form-item>
            </a-form>
          </template>
          <template #operationBtns>
            <div class="buttons-wrap right">
              <Tooltip :title="'PM批量变更'">
                <a-button class="right" type="primary" ghost @click="pmBatchChange" :disabled="selectedPMId.length === 0">PM批量变更</a-button>
              </Tooltip>
            </div>
          </template>
        </FilterLayout>
      </div>
    <div class="table-box">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        :pagination="pagination"
        :rowKey="(record) => record.engagementCode+record.pmId"
        :scroll="{ y: tableHeight, x: tableWidth }"
        :row-selection="{
            selectedRowKeys: selectedPMId,
            selectedRows: selectedPMList,
            onChange: pmSelect,
            getCheckboxProps: getCheckboxPropsFunc,
        }"
      >
        <template #action="{ record }">
<!--          <a-space>
            <span
              :class="(record.hardBook === '0' && record.inProgress === '0')? 'operationLink underline disabled' : 'operationLink underline'"
              @click="openPmChangeModal(record)"
              v-auth="['system:infoMaintain:pmChange']"
            >
              PM变更
            </span>
            <span
                class="operationLink underline"
                @click="pmRecordClick(record)"
            >
              变更记录
            </span>
          </a-space>-->
          <ActionBtn v-bind="record" :btnList="btnList" @SwapOutlined="openPmChangeModal(record)" @HistoryOutlined="pmRecordClick(record)" />
        </template>
      </a-table>
    </div>
  </section>
  <notice-modal
    v-model:modalVisible="changePmModalVisible"
    :modalWidth="500 + 'px'"
    @close-modal="closePmModal"
    @sumbit-modal="sumbitChangePm"
    sumbitBtn="确定"
    cancelBtn="取消"
  >
    <div>
      <span>变更PM：</span>
      <a-select
        v-model:value="newPm"
        style="width: 300px"
        placeholder="选择PM"
        show-search
      >
        <a-select-option
          v-for="item in pmList"
          :key="item.id"
          :value="
            item.fullName +
            '/' +
            item.email +
            item.fullName.replace(', ', '') +
            item.fullName.replace(', ', ',')
          "
        >
          {{ item.fullName + "/" + item.email }}
        </a-select-option>
      </a-select>
    </div>
  </notice-modal>
<!--  pm批量变更弹窗-->
  <a-modal
      :getContainer="() => $refs.pmRef"
      v-model:visible="pmBatchVisible"
      title="PM批量变更"
      width="26vw"
      centered
      @cancel="cancelModal"
  >
    <template #footer>
      <div style="display: flex;align-items: center;justify-content: space-between;">
          <div style="display:flex;flex:4">
            <div>是否发送邮件<span style="color:red;margin-right:10px;margin-left:5px">*</span></div>
            <div>
              <a-radio-group v-model:value="batchSend">
                <a-radio value="1">是</a-radio>
                <a-radio value="0">否</a-radio>
              </a-radio-group>
            </div>
          </div>
          <div style="display:flex;align-items:center;flex:2;margin-left: 10px;">
            <a-button @click="pmBatchCancel">取消</a-button>
            <a-button type="primary" @click="pmBatchConfirm">确定</a-button>
          </div>
        </div>
      </template>
    <div>
      <div style="color:#505050">PM变更为</div>
      <a-select
          v-model:value="newPm"
          style="width: 100%"
          placeholder="请选择"
          show-search
          :filterOption="filterOption"
      >
        <a-select-option
            v-for="item in pmList"
            :key="item.id"
            :value="item.id"
        >
          {{ item.fullName + "/" + item.email }}
        </a-select-option>
      </a-select>
      <div style="color: #FC6B08;">（注意！所有已选的Engagement Code对应的在途调令中PM均会变更为该PM）</div>
    </div>
  </a-modal>
<!--  变更记录弹窗-->
  <PMChangeRecordModal :zIndex="1001" v-model:visible="pmRecordVisible" :id="pmId" title="变更记录" :pmRecordData="pmRecordData"
                     @close-modal="closePMRecordModal()" />
<!--  PM变更弹窗-->
  <PMChangeModal
      v-model:visible="pmChangeVisible"
      title="PM变更"
      @close-modal="closePMChangeModal()"
      @confirm-modal="confirmPMChangeModal"
      :pmListForSearch="pmList"
      :pmChangeData="pmChangeData"
  />
</template>

<script>
import NoticeModal from "@/components/NoticeModal.vue";
import {
  defineComponent,
  onUnmounted,
  reactive,
  ref,
  nextTick,
  onActivated,
  watch,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";
import { message } from "ant-design-vue";
import {cloneDeep} from "lodash";
import PMChangeRecordModal from "@/views/home/ResourceManagement/PM/components/PMChangeRecordModal";
import PMChangeModal from "@/views/home/ResourceManagement/PM/components/PMChangeModal";
import ActionBtn from '@/components/ActionBtn'
import Tooltip from '@/components/Tooltip'
import {onBeforeRouteLeave, useRoute} from "vue-router";
const btnList = [
  {
    name: 'PM变更',
    icon: 'SwapOutlined',
    auth: ['system:infoMaintain:pmChange']
  },
  {
    name: '变更记录',
    icon: 'HistoryOutlined',
    auth: []
  },
]
const columns = [
  {
    title: "Engagement Code",
    dataIndex: "engagementCode",
    key: "engagementCode",
    slots: { customRender: "engagementCode" },
    width: 150,
    ellipsis: true,
  },
  {
    title: "Engagement Description",
    dataIndex: "engagementDescription",
    key: "clientName",
    ellipsis: true,
    width: 200,
  },
  {
    title: "PM Name",
    dataIndex: "pmInfo",
    key: "pmInfo",
    ellipsis: true,
    width: 200,
    customRender: (item) => item.text ? item.text.split("/").join("\n") : null,
    customCell:() => {
            return {
              style: {
                lineHeight: 1.2,
                padding:'6px 10px',
              }
            }
          }
  },
  {
    title: "未上项目人数",
    dataIndex: "hardBook",
    key: "hardBook",
    width: 130,
  },
  {
    title: "进行中人数",
    dataIndex: "inProgress",
    key: "inProgress",
    width: 130,
  },
  {
    title: "已完成人数",
    dataIndex: "completed",
    key: "completed",
    width: 130,
  },
  {
    title: "已失效人数",
    dataIndex: "invalid",
    key: "invalid",
    width: 130,
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    slots: { customRender: "action" },
    width: 100,
    fixed: "right",
  },
];

export default defineComponent({
  name: "maintainPm",
  components: {Tooltip, ActionBtn, PMChangeModal, PMChangeRecordModal, NoticeModal },
  setup() {
    const dataSource = ref([]);
    const tableHeight = ref(0);
    const tableWidth = ref(0);
    const pmListForSearch = ref([])
    const pmList = ref([]);
    const formRef = ref(null);
    const changePmModalVisible = ref(false);
    const formState = ref({
      pmId: undefined,
      engagementDescription: "",
    });
    const newPm = ref("");
    const { $api } = useGlobalPropertyHook();
    let currentState  = {}
    const updateParams =() => {
      currentState = cloneDeep(formState.value);
    }
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共${total}条`;
      },
      onChange: (page, pageSize) => {
        pagination.current = page;
        pagination.pageSize = pageSize;
        getPmTableList(pagination);
      },
      onShowSizeChange: (_, size) => {
        pagination.current = 1;
        pagination.pageSize = size;
        getPmTableList(pagination);
      },
    });
    const doSearch = () => {
      updateParams()
      pagination.current = 1
      selectedPMId.value = []
      selectedPMList.value = []
      getPmTableList(pagination)
    }
    const getPmTableList = ({ current = 1, pageSize }) => {
      let pm = pmListForSearch.value.find(
        (item) =>
          item.fullName +
            "/" +
            item.email +
            item.fullName.replace(", ", "") +
            item.fullName.replace(", ", ",") ===
            currentState.pmId
      );
      let pmId = "";
      if (currentState.pmId) {
        pmId = pm.id;
      }
      const data = {
        pageIndex: current,
        pageSize: pageSize,
        pmId: pmId,
        engagementDescription: currentState.engagementDescription,
      };
      $api.getPmsList(data).then((res) => {
        dataSource.value = res.data.resource;
        pagination.total = res.data.total;
      });
    };

    
    // getPmTableList();

    const handleChange = () => {
      // 搜索框下拉
      $api.findAllPM().then((res) => {
        pmListForSearch.value = res.data;
        console.log('findAllPM:', pmListForSearch.value)
      });
      // PM变更下拉
      $api.findMatchingPM().then((res) => {
        pmList.value = res.data;
        console.log('findMatchingPM:', pmList.value)
      });

    };

    const resetForm = () => {
      formRef.value.resetFields();
      formState.value.pmId = undefined;
      pagination.current = 1
      selectedPMId.value = []
      selectedPMList.value = []
      updateParams()
      getPmTableList(pagination);
    };

    let code = "";
    let oldPmId = "";
    const pmChangeData = ref()
    const openPmChangeModal = (record) => {
      pmChangeData.value = record
      pmChangeVisible.value = true
    };

    const closePmModal = () => {
      changePmModalVisible.value = false;
      newPm.value = "";
    };

    const sumbitChangePm = () => {
      let newPmId = pmList.value.find(
        (item) =>
          item.fullName +
            "/" +
            item.email +
            item.fullName.replace(", ", "") +
            item.fullName.replace(", ", ",") ===
          newPm.value
      );
      if (newPm.value?.length) {
        const params = {
          engagementCode: code,
          newPmId: newPmId.id,
          pmId: oldPmId,
        };
        $api.modifyPM(params).then((res) => {
          if (res.code == 200) {
            closePmModal();
            getPmTableList(pagination);
          }
        });
      } else {
        message.warning("请选择PM");
      }
    };

    const selectedPMId = ref([])
    const selectedPMList = ref([])
    const pmSelect = (selectedRowKeys, selectedRows) => {
      selectedPMId.value=selectedRowKeys
      selectedPMList.value = selectedRows
    }
    const onSelect = (record,selected) => {
      if (selected) {
        selectedPMList.value.push(record)
      }else{
        const delIndex = selectedPMList.value.findIndex((val) => {
          return val.engagementCode+val.pmId === record.engagementCode+record.pmId
        })
        selectedPMList.value.splice(delIndex, 1)
      }
    }
    const getCheckboxPropsFunc = (record) => {
      return {
        disabled: record.hardBook ==='0' && record.inProgress === '0'
      };
    };
    console.log(selectedPMList.value,'测试')
    const pmBatchChange = () => {
      pmBatchVisible.value = true
    }
    const pmBatchVisible = ref(false)
    const pmBatchConfirm = () => {
      const params = selectedPMList.value.map(item => {
        return {
          newPmId:newPm.value,
          pmId: item.pmId,
          engagementCode: item.engagementCode,
          isSendEmail:batchSend.value
        }
      })
      $api.PmChangeModify(params).then(() => {
        doSearch()
        message.success('操作成功')
        pmBatchVisible.value = false
        newPm.value = ''
      });
    }
    const pmBatchCancel = () => {
      pmBatchVisible.value = false
      batchSend.value = '1'
      newPm.value = ''
    }

    //变更记录
    const pmRecordData = ref([])
    const pmRecordClick = (record) => {
      pmRecordData.value = record
      pmRecordVisible.value = true
    }
    const pmId = ref('')
    const pmRecordVisible = ref(false)
    const closePMRecordModal = () => {
      pmRecordVisible.value = false
    }

    //PM变更
    const pmChangeVisible = ref(false)
    const closePMChangeModal = () => {
      pmChangeVisible.value = false
    }
    const confirmPMChangeModal = () => {
      pmChangeVisible.value = false
      doSearch()
    }

    const getSize = () => {
      nextTick(() => {
        const bodyWidth = document.body.clientWidth;
        tableWidth.value = bodyWidth - (93 / 1920) * bodyWidth;
        tableHeight.value = useTableHeight();
      });
    };
    const filterOption = (input, option) => {
      return (
          option.children[0].children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    };

    const route = useRoute();
    watch(
        () => route.query.id,
        async (val) => {
          if(val){
            await $api.findAllPM().then((res) => {
              pmListForSearch.value = res.data
            });
            let pmid =  pmListForSearch.value.find((item) => item.id === route.query.id);
            formState.value.engagementDescription = ""
            formState.value.pmId = pmid.fullName + "/" + pmid.email + pmid.fullName.replace(", ", "") + pmid.fullName.replace(", ", ",")
            await doSearch()
          }
        },
        {immediate:true}
    )

    window.addEventListener("resize", getSize);
    onBeforeRouteLeave(() => {
      route.query.id = ''
    });

    onActivated(() => {
      if(!route.query.id) {
        getPmTableList(pagination);
      }
      getSize();
      handleChange();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    let batchSend = ref('1')
    const cancelModal = () => {
      pmBatchVisible.value = false
      batchSend.value = '1'
      newPm.value = ''
    }
    return {
      dataSource,
      pagination,
      columns,
      formState,
      formRef,
      newPm,
      getPmTableList,
      tableHeight,
      tableWidth,
      handleChange,
      pmList,
      pmListForSearch,
      closePmModal,
      resetForm,
      changePmModalVisible,
      openPmChangeModal,
      sumbitChangePm,
      doSearch,
      updateParams,
      pmBatchChange,
      pmBatchVisible,
      pmBatchConfirm,
      pmRecordClick,
      pmRecordVisible,
      closePMRecordModal,
      pmId,
      pmChangeVisible,
      closePMChangeModal,
      selectedPMId,
      selectedPMList,
      pmSelect,
      pmChangeData,
      pmBatchCancel,
      pmRecordData,
      confirmPMChangeModal,
      filterOption,
      getCheckboxPropsFunc,
      onSelect,
      btnList,
      batchSend,
      cancelModal
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/style/aModal.less";
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector){
  border: 1px solid #cccccc !important;
}
:deep(.ant-select-arrow) {
  color: #cccccc;
}
</style>
