<template>
  <a-modal
    :getContainer="getRefsAff"
    :visible="visible"
    @cancel="closeModal"
    width="41vw"
    centered
  >
    <!-- <a-divider /> -->
    <template #footer>
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div style="display: flex;align-items: center;flex:5">
          <div>是否发送邮件<span style="color:red;margin-right:10px;margin-left:5px">*</span></div>
            <div>
              <a-radio-group v-model:value="batchSend">
                <a-radio value="1">是</a-radio>
                <a-radio value="0">否</a-radio>
              </a-radio-group>
            </div>
        </div>
        <div class="info" >（Candidate Name请至少选择一名）</div>
        <div style="display:flex;">
          <a-button @click="closeModal">取消</a-button>
          <a-button
            type="primary"
            @click="handleOk"
            style="margin-left: 10px"
            :disabled="selectedPMId.length === 0 || !formDate.pmChange"
            >确定</a-button>
        </div>
      </div>
    </template>
    <template #title>
      <strong class="modal-title">
        {{ title }}
      </strong>
    </template>
    <div>
      <a-form :model="formState" layout="vertical">
        <a-form-item label="搜索" name="Name">
          <a-input-search
            v-model:value="formState.consultName"
            placeholder="请输入Name/GGID"
            allowClear
            @search="searchConsultName"
          />
        </a-form-item>
      </a-form>
    </div>
    <div class="tablecontainer" @scroll="onScrollCapture">
      <a-table
        :data-source="tableData"
        :columns="columns"
        rowKey="id"
        :loading="loading"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedPMId,
          onChange: pmSelect,
        }"
      >
      </a-table>
    </div>
    <div style="margin-top: 0.8vw">
      <a-form :model="formDate" layout="vertical">
        <a-form-item label="PM变更为" name="pmChange">
          <a-select
            v-model:value="formDate.pmChange"
            style="width: 100%"
            placeholder="请选择"
            show-search
            allowClear
            :disabled="selectedPMId.length === 0"
            :filterOption="filterOption"
            :dropdownStyle="{maxHeight: '30vh',}"
          >
            <a-select-option
              v-for="item in pmList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.fullName + "/" + item.email }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
  <div ref="pmChangeModalRef">
    <a-modal
        v-model:visible="pmBatcConfirmVisible"
        :getContainer="()=>$refs.pmChangeModalRef"
        title="提示"
        ok-text="确定"
        cancel-text="取消"
        @ok="pmBatchConfirm"
        @cancel="pmBatcConfirmVisible = false"
        width="20.8vw"
        :z-index="10001"
        centered
    >
      <div>
        <ExclamationCircleOutlined style="color: #fdcd46" />
        请确定是否变更已选顾问的PM人员？
      </div>
    </a-modal>
  </div>
</template>

<script>
import { useGlobalPropertyHook } from "@/hooks/common";
import { computed, reactive, ref, watch } from "vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

export default {
  name: "PMChangeModal",
  components: { ExclamationCircleOutlined },
  props: {
    visible: Boolean,
    title: String,
    pmListForSearch: {
      type: Array,
      default: () => [],
    },
    pmChangeData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["close-modal", "confirm-modal"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formState = reactive({
      consultName: "",
    });
    const formDate = reactive({
      pmChange: "",
    });
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
    });

    const columns = ref([
      {
        title: "Candidate Name",
        dataIndex: "consultant",
        ellipsis: true,
      },
    ]);
    const tableData = ref([]);
    const getTableData = () => {
      const data = {
        condition: "",
        pmId: props.pmChangeData.pmId,
        engagementCode: props.pmChangeData.engagementCode,
      };
      $api.queryConsultant(data).then((res) => {
        tableData.value = res.data;
        pagination.total = res.data.total;
      });
    };
    const searchConsultName = () => {
      const data = {
        condition: formState.consultName,
        pmId: props.pmChangeData.pmId,
        engagementCode: props.pmChangeData.engagementCode,
      };
      $api.queryConsultant(data).then((res) => {
        tableData.value = res.data;
        pagination.total = res.data.total;
      });
    };
    //滚动加载
    // const onScrollCapture = (event) => {
    //   console.log(event.target.scrollTop,event.target.scrollHeight,event.target.clientHeight)
    //   if (Math.round(event.target.scrollTop) === (event.target.scrollHeight - event.target.clientHeight)) {
    //     //loading.value = true;
    //     setTimeout(() => {
    //       pagination.current = pagination.current + 1;
    //       getTableData();
    //     }, 100);
    //   }
    // }
    const loading = ref(false);
    const pmList = computed(() => props.pmListForSearch);

    const selectedPMId = ref([]);
    const pmSelect = (selectedRowKeys) => {
      selectedPMId.value = selectedRowKeys;
      console.log(selectedPMId.value);
    };

    const filterOption = (input, option) => {
      return (
          option.children[0].children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    };

    watch(
      () => props.visible,
      (val) => {
        if (val) {
          tableData.value = [];
          pagination.current = 1;
          formState.consultName = "";
          formDate.pmChange = "";
          getTableData();
        }
      }
    );

    const handleOk = () => {
      pmBatcConfirmVisible.value = true;
    };

    const pmBatcConfirmVisible = ref(false);
    const pmBatchConfirm = () => {
      pmBatcConfirmVisible.value = false;
      const data = {
        newPmId: formDate.pmChange,
        userId: selectedPMId.value,
        pmId: props.pmChangeData.pmId,
        engagementCode: props.pmChangeData.engagementCode,
        isSendEmail:batchSend.value
      };
      $api.consultantPmChange(data).then(() => {
        message.success("操作成功");
        ctx.emit("confirm-modal");
      });
    };

    const closeModal = () => {
      selectedPMId.value = [];
      batchSend.value = '1'
      ctx.emit("close-modal");
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };
    watch(
      () => selectedPMId.value,
      (val) => {
        if (val.length === 0) {
          formDate.pmChange = "";
        }
      }
    );
    let batchSend = ref('1')
    return {
      columns,
      tableData,
      getRefsAff,
      closeModal,
      pagination,
      formState,
      formDate,
      pmList,
      handleOk,
      pmBatcConfirmVisible,
      pmBatchConfirm,
      pmSelect,
      selectedPMId,
      //onScrollCapture,
      loading,
      searchConsultName,
      filterOption,
      batchSend
    };
  },
};
</script>

<style scoped lang="less">
@import "~@/style/aModal.less";
.ant-input-affix-wrapper {
  border: 1px solid #cccccc;
}
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border: 1px solid #cccccc !important;
}
:deep(.ant-select-arrow) {
  color: #cccccc;
}
:deep(.ant-input-search-icon, .ant-select-arrow) {
  color: #cccccc;
}
.info {
  color: var(--3182-ce, #3182ce);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  float: left;
  height: 20px;
  line-height: 20px;
}
.tablecontainer {
  max-height: 350px;
  overflow-y: scroll;
}
</style>
